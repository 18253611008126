/* Utilities */

.grid-margin {
  margin-bottom: $card-spacing-y;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-sm {
  width: 43px;
  height: 43px;

  &.rounded-10 {
    border-radius: 10px;
  }
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 35px;
  height: 35px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  >.card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #8c8c8c;
}

.text-black {
  color: $black;
}

.text-small {
  font-size: 12px;

  &.ps-4 {
    padding-left: 30px !important;
  }
}

.flex-grow {
  flex-grow: 1;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-normal {
  font-weight: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.image-grouped {
  display: flex;

  .text-avatar,
  img {
    @extend .img-ss;
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $card-bg;
    transform: scale(1);
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-property: "box-shadow", "z-index", "transform", "border-width";

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;

    }
  }

  .text-avatar {
    @extend .bg-inverse-primary;
    color: $primary;
    font-size: 11px;
    font-weight: 600;
  }
}

.dot-indicator {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.aligner-wrapper {
  position: relative;

  .absolute {
    position: absolute;

    &.absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }
  }
}

.v-strock-2 {
  width: 3px;
}

.min-width-cell {
  min-width: 110px;
}

.font-weight-medium {
  font-weight: 500;
}

.media {
  display: flex;
}

.settings-nav-bottom {
  position: fixed;
  bottom: 10px;
  width: 220px;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
}

.h-100 {
  height: 65vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.list.active {
  background: #ffe2e2;
  box-shadow: 0 3px 4px #c2c2c2;
}

.list {
  .dropdown {
    .dropdown-toggle:after {
      content: none;
    }
  }
}

.z-0 {
  z-index: 0 !important;
}

.shadow {
  box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  margin: 0;
}

.MuiTypography-root {
  font-family: Manrope, sans-serif;
}

.modal {
  --bs-modal-width: 800px;
}

.zoom-on-hover {
  transition: transform 0.3s ease-in-out;
  /* Smooth transition effect */
}

.zoom-on-hover:hover {
  transform: scale(1.05);
  /* Zoom in 1.1 times */
}


.animate-col-md-6 {
  transition: width 0.3s ease-in-out;
  /* Add transition property for width */
}

.animate-d-none {
  width: 0;
  /* Set width to 0 when hidden */
  overflow: hidden;
  /* Hide the content when width is 0 */
}

tr.MuiTableRow-root {
  height: inherit !important;
}

.table td {
  text-wrap: initial !important;
}

.header-blue {

  background: #3771be;
  color: white;

}

.body-blue {
  background: #fff;
}

.not-print .body-blue {
  background: #fff;
}

.header-logo {
  background-color: white;
  padding: 1rem !important;
  border-radius: 10px;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.white-bg-border-radius-10 {
  background-color: white;
  border-radius: 10px;
}

.form-control-plaintext {
  font-size: 16px;
  padding: 0.175rem;
  margin-bottom: 10px;
}

.img-checkbox {
  position: absolute;
  top: 30px;
  right: 0;
}

.font-lg {
  font-size: 18px !important;
}

.table thead th,
.table tbody td,
.table tbody th,
.table tr,
.table td,
.table th,
.text-16 {
  padding: 4px !important;
  font-size: 16px !important;
}

.text-16 {
  font-size: 16px;
}

.table td ul {
  margin: 0px;
}

.react-photo-gallery--gallery>div>img {
  flex-flow: nowrap !important;
  width: 150px;
  height: 150px;
}

.MuiDropzoneArea-root {

  min-height: 150px;
}

.table ul li,
.table ol li {
  font-size: 16px;
}

input.form-control {
  height: 38px;
  margin-bottom: 0px;
}

.m-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.not-print .col-form-label {
  font-size: 12px;
  font-weight: bold;
}

.not-print .table-header-sm tr th {
  font-size: 12px !important;
  font-weight: bold !important;
}

.not-print .padding-table.table tr {
  padding: 9px !important;
  font-size: 16px !important;
}

.not-print .text-strong {
  font-weight: bold;
}

.react-select__control {
  margin-bottom: 15px;
}

.react-pdf__Page {
  border-bottom: 7px solid #f4f5f7;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.react-pdf__Page {
  background-color: #f4f5f7 !important;
}

.h-250 {
  height: 25rem;
  overflow-y: auto;
}

.h-250::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #F5F5F5;
}

.h-250::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.h-250::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1F3BB3;
}

.total-value {
  text-align: right;
  margin-right: 231px;
}

.medium-modal .modal-lg {
  --bs-modal-width: 50% !important;
}

.obs-content,
.obs-title {
  font-size: 16px;

  font-weight: bold;
}

.obs-title {
  color: darkgray;
}

.obs-header {
  background-color: #F9FAFB;
}

.obs-section {
  border-bottom: 1px solid #eee;
}

.font-sm.table tbody td,
.font-sm.table thead th {
  font-size: 12px !important;
}

.nested-modal {
  --bs-backdrop-zindex: 1055;
  z-index: 1055;
}

.required:after {
  content: " *";
  color: red;
}
.card-eptw {
  padding: 3px 8px;
  background: rgb(188 196 255);
  border-radius: 50px;
  font-size: 10px;
  color: #2323d7;
}
.box {
  padding: 10px;
  border: 1px solid #ddd;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.pack-container {
  margin: 20px auto;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.pack-header {

  align-items: center;
  border-bottom: 2px solid #007BFF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.pack-header-image {
  width: 150px;
  height: auto;
  margin-right: 20px;
}

.pack-header-text {
  flex: 1;
}

.pack-header-text h1 {
  margin: 0;
  font-size: 1.2em;
  color: #007BFF;
}

.pack-header-text p {
  margin: 5px 0;
  font-size: 0.9em;
}

.pack-content {
  margin-top: 20px;
}

.pack-section {

  padding: 10px;
  background-color: #ffffff;
}

.pack-section h2 {
  margin-bottom: 10px;
  font-size: 1em;
  color: #007BFF;
}

.pack-section ul {
  padding-left: 20px;
  font-size: 0.9em;
}

.pack-section ul li {
  margin-bottom: 10px;
}

.pack-risk-level {
  font-weight: bold;
  color: red;
  font-size: 0.9em;
}

.MuiAccordion-root {
  background: #003b785c!important;
}

.MuiCollapse-root {
  background: white!important;
  border-radius: 10px;
  padding-top: 10px;
  margin-bottom: 15px;

}